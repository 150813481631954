// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "channels"
import "../stylesheets/About.css"
import "../stylesheets/application.css"
import "../stylesheets/Cemetery.css"
import "../stylesheets/Dropzone.css"
import "../stylesheets/Family.css"
import "../stylesheets/Image.css"
import "../stylesheets/LandingPage.css"
import "../stylesheets/Person.css"
import "../stylesheets/Record.css"
import "../stylesheets/Results.css"
import "../stylesheets/Search.css"
import "../stylesheets/SelectablePersonBox.css"
import "../stylesheets/Fact.css"
import "../stylesheets/leaflet.css"
import "../stylesheets/ResizableBox.css"
import "../stylesheets/Popover.css"
import "../stylesheets/react-resizable.css"

import "../images/annapolis/landingPage.jpg"
